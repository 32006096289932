<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ translations.tcCampHome }}</h1>
    </header>
    <!-- / Page Header -->
    <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
    <div v-if="allowPage">
      <camp-selector @camp_changed="page_load()" :tooltip="translations.tcCurrentHome" :i18n="translations.components">
      </camp-selector>
      <!-- / Camp Selector -->
      <div class="page-body container mx-med">
        <section class="section-2">
          <b-button v-if="iCanSee(view_calendar_event_controls.calendar_add_event_button)" @click="handleAddEventClick()"
            class="btn btn-primary btn-w-med">
            {{ translations.tcAddMeeting }}</b-button>
        </section>
        <section class="section-3">
          <div class="row">
            <InfoCard v-for="(info, index) in filteredTiles" :key="index" :obj="info"
              @set_program="handleSetProgram($event)" />
          </div>
        </section>
      </div>
    </div>
    <!-- / Page Body -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import InfoCard from '@/components/info-card/InfoCard.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { securityMixin } from '@/mixins/securityMixin'
import { staffUserMixin } from '@/mixins/staffUserMixin'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'camp-officers-home',
  mixins: [securityMixin, staffUserMixin, translationMixin],
  data() {
    return {
      translations: {},
      view_calendar_event_controls: {
        calendar_add_event_button: '20a78086-7644-4a38-9162-085f27489166',
      },
      secured_controls: {
        camp_elections_tile: 'b1f38a78-5b63-44ec-99b0-2969ccc24663',
        camp_treasury_tools_tile: 'cc6c62b6-a4df-49e8-981f-fba2cca7818a',
        camp_website_security_manager_tile: 'de3d59ca-5a37-489c-8a01-82f91f3fc821',
        view_IO_application_upload: '93e448b0-7901-4625-ba55-0ee8ad5b8059',
        view_validate_membership_application_tile: 'd27ab1f4-0be5-4384-8197-7de2773c9eb0',
      },
      filteredTiles: [],
      campHomeTiles: [
        {
          display: false,
          class: 'col col-6',
          title: '', // Camp Elections
          content: '',
          link: '/officers/camp/election/view-camp-election',
          label: '', // View More
          img: {
            src: 'svgs/church-officers.svg',
            alt: '', // Camp Elections
            width: 64,
            height: 64,
          },
        },
        {
          display: false,
          class: 'col col-6',
          title: '', // Camp Treasury,
          content: '',
          link: '/officers/camp/treasury-tools/c',
          label: '', // View More
          img: {
            src: 'svgs/applications-icon.svg',
            alt: '', // Camp Treasury
            width: 64,
            height: 64,
          },
        },
        {
          display: true,
          class: 'col col-6',
          title: '', // Camp Goal Planner
          content: '',
          link: '/officers/camp/goal-planner/cm',
          label: '', // View More
          img: {
            src: 'svgs/church-reports.svg',
            alt: '', // Camp Goal Planner,
            width: 64,
            height: 64,
          },
        },
        {
          display: true,
          class: 'col col-6',
          title: '', // Security Manager
          content: '',
          link: '/officers/camp/website-security-manager/cm',
          label: '', // View More
          img: {
            src: 'svgs/security-icon.svg',
            alt: '', // Security Manager
            width: 64,
            height: 64,
          },
        },
        {
          display: true,
          class: 'col col-6',
          title: '', // Officer Resources
          content: '',
          link: '/resources/media-portal/program',
          label: '', // View More
          img: {
            src: 'svgs/program-resources.svg',
            alt: '', // Officer Resources
            width: 64,
            height: 64,
          },
        },
        {
          display: true,
          class: 'col col-6',
          title: '', // Member Churches
          content: '',
          link: '/officers/camp/camp-member-churches',
          label: '', // Vie w More
          img: {
            src: 'svgs/CampChurch.svg',
            alt: '', // Member Churches
            width: 64,
            height: 64,
          },
        },
      ],
      defaultTiles: [
        {
          display: true,
          class: 'col col-6',
          title: '', // Validate Membership Applications
          content: '',
          link: '/officers/camp/validate-membership-applications/cm',
          label: '', // View More
          img: {
            src: 'svgs/church-list.svg',
            alt: '', // Validate Membership Applications
            width: 64,
            height: 64,
          },
        },
        {
          display: false,
          class: 'col col-6',
          title: 'Secure Application Upload',
          content: '',
          link: '/officers/camp/application-upload-file',
          label: '', // View More
          img: {
            src: 'svgs/church-list.svg',
            alt: '', // Validate Membership Applications
            width: 64,
            height: 64,
          },
        },
        {
          display: true,
          class: 'col col-6',
          title: '', // Camp Profile
          content: '',
          link: '/officers/camp/camp-profile/cm',
          itemId: '1B3A2CD6-63D0-4050-891F-54549C33B7A8', // The sitecore ItemId for ChurchMinistry Program
          label: 'View More',
          img: {
            src: 'yellow-tent-icon.png',
            alt: '', // Camp Profile
            width: 64,
            height: 64,
          },
        },
      ],
    }
  },
  components: {
    campSelector: CampSelect,
    InfoCard: InfoCard,
    securityBanner: SecurityBanner,
  },
  methods: {
    ...mapActions({
      getControlPermissionsFromList: 'user/getControlPermissionsFromList',
      setLoadingStatus: 'menu/setLoadingStatus',
      setMRPProgramId: 'mrp/setMRPProgramId',
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          this.getViewTranslations(),
          this.getComponentTranslations('security-banner', 'camp-select'),
        ]).then((results) => {
          const componentTranslations = results[1]
          this.$set(this.translations, 'components', componentTranslations)
          this.loadTranslations()
        })
        await this.checkPermissions()
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    campTilesFiltered() {
      this.filteredTiles = [...this.campHomeTiles, ...this.defaultTiles].filter((ct) => ct.display === true)
    },
    handleAddEventClick() {
      this.$router.push({ path: '/calendar/camp-calendar/add-event' })
    },
    async handleSetProgram(data) {
      this.setMRPProgramId(data.itemId)
      this.$router.push(data.link)
    },
    loadTranslations() {
      this.campHomeTiles[0].title = this.translations.tcCampElectionsTitle
      this.campHomeTiles[0].content = this.translations.tcCampElectionsDescription
      this.campHomeTiles[0].label = this.translations.tcViewMore
      this.campHomeTiles[0].img.alt = this.translations.tcCampElectionsTitle

      this.campHomeTiles[1].title = this.translations.tcCampTreasuryTitle
      this.campHomeTiles[1].content = this.translations.tcCampTreasuryDescription
      this.campHomeTiles[1].label = this.translations.tcViewMore
      this.campHomeTiles[1].img.alt = this.translations.tcCampTreasuryTitle

      this.campHomeTiles[2].title = this.translations.tcCampGoalPlannerTitle
      this.campHomeTiles[2].content = this.translations.tcCampGoalPlannerDescription
      this.campHomeTiles[2].label = this.translations.tcViewMore
      this.campHomeTiles[2].img.alt = this.translations.tcCampGoalPlannerTitle

      this.campHomeTiles[3].title = this.translations.tcSecurityManagerTitle
      this.campHomeTiles[3].content = this.translations.tcSecurityManagerDescription
      this.campHomeTiles[3].label = this.translations.tcViewMore
      this.campHomeTiles[3].img.alt = this.translations.tcSecurityManagerTitle

      this.campHomeTiles[4].title = this.translations.tcOfficerResourcesTitle
      this.campHomeTiles[4].content = this.translations.tcOfficerResourcesDescription
      this.campHomeTiles[4].label = this.translations.tcViewMore
      this.campHomeTiles[4].img.alt = this.translations.tcOfficerResourcesTitle

      this.campHomeTiles[5].title = this.translations.tcCampMemberTitle
      this.campHomeTiles[5].content = this.translations.tcCampMemberDescription
      this.campHomeTiles[5].label = this.translations.tcViewMore
      this.campHomeTiles[5].img.alt = this.translations.tcCampMemberTitle

      this.defaultTiles[0].title = this.translations.tcValidateMembershipApplicationsTitle
      this.defaultTiles[0].content = this.translations.tcValidateMembershipApplicationsDescription
      this.defaultTiles[0].label = this.translations.tcViewMore
      this.defaultTiles[0].img.alt = this.translations.tcValidateMembershipApplicationsTitle

      this.defaultTiles[1].title = this.translations.tcValidateIOCMembershipApplicationsTitle
      this.defaultTiles[1].content = this.translations.tcValidateIOCMembershipApplicationsDescription
      this.defaultTiles[1].label = this.translations.tcViewMore
      this.defaultTiles[1].img.alt = this.translations.tcValidateIOCMembershipApplicationsTitle

      this.defaultTiles[2].title = this.translations.tcCampProfileTitle
      this.defaultTiles[2].content = this.translations.tcCampProfileDescription
      this.defaultTiles[2].label = this.translations.tcViewMore
      this.defaultTiles[2].img.alt = this.translations.tcCampProfileTitle
    },
    async checkPermissions() {
      await this.getControlPermissionsFromList(Object.values(this.secured_controls)).then(() => {
        this.campHomeTiles[0].display = this.determineAccessibility(
          this.secured_controls.camp_elections_tile
        )
        this.campHomeTiles[1].display =
          (this.determineAccessibility(this.secured_controls.camp_treasury_tools_tile) &&
            this.userLogin.membertype.toLowerCase() === "usa") ||
          this.isStaffMember
        this.campHomeTiles[2].display = this.userLogin.membertype.toLowerCase() === "usa"
        this.campHomeTiles[3].display = this.determineAccessibility(
          this.secured_controls.camp_website_security_manager_tile
        )
        this.defaultTiles[0].display =
          this.determineAccessibility(this.secured_controls.view_validate_membership_application_tile) &&
          this.userLogin.membertype.toLowerCase() === "usa"
        this.defaultTiles[1].display =
          this.determineAccessibility(this.secured_controls.view_IO_application_upload) &&
          !['icc', 'ioc'].includes(this.userLogin.membertype.toLowerCase())
        this.campTilesFiltered()
      })
    },
  },
  computed: {
    ...mapGetters({
      iCanSee: 'user/iCanSee',
      isInMenu: 'menu/isInMenu',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      userLogin: 'user/userLogin',
    }),
    allowPage() {
      return this.isInMenu(this.$route.path)
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.church-ministry {
  .page-body {
    margin-top: 15px;

    @include breakpoint(sm) {
      margin-top: 15px;
    }

    h4 {
      margin-bottom: 36px;
    }
  }

  .section-1,
  .section-2 {
    margin-bottom: 80px;

    @include breakpoint(sm) {
      margin-bottom: 3rem;
    }

    >.row {
      margin-bottom: 28px;
    }

    .col {
      &:first-of-type {
        padding-right: 23px;
      }

      &:last-of-type {
        padding-left: 23px;
      }

      @include breakpoint(sm) {
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }

  .section-3 {
    margin-bottom: 60px;

    .col {
      &:nth-child(2n-1) {
        padding-right: 23px;
      }

      &:nth-child(2n) {
        padding-left: 23px;
      }
    }

    @include breakpoint(sm) {
      .col {
        height: 100%;
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
}
</style>
